import { render, staticRenderFns } from "./IrrigationSmartDetail.vue?vue&type=template&id=19631b49&scoped=true"
import script from "./IrrigationSmartDetail.vue?vue&type=script&lang=ts"
export * from "./IrrigationSmartDetail.vue?vue&type=script&lang=ts"
import style0 from "./IrrigationSmartDetail.vue?vue&type=style&index=0&id=19631b49&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "19631b49",
  null
  
)

export default component.exports