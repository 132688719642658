
import { Component, Vue } from 'vue-property-decorator'
import { TimingPlan } from '@/types/irrigation'
import { intellectIrrigationDesc } from '@/utils/config'

@Component
export default class IrrigationTimingDetail extends Vue {
  private info: TimingPlan = {
    planName: '',
    projectAreaId: '',
    projectId: '',
    dateRange: [],

    concurrentNum: '',
    keepLength: ''
  }

  get intellectIrrigationDesc () {
    return intellectIrrigationDesc
  }

  created () {
    this.getPlanDetail(this.$route.params.id)
  }

  getPlanDetail (id: string) {
    this.$axios.get(this.$apis.irrigation.irrigationPlanDetail, {
      planId: id
    }).then((res) => {
      this.info = {
        planId: res.planId,
        planName: res.planName,
        projectAreaName: res.projectAreaName,
        projectId: res.projectId,
        startDate: res.startDate,
        endDate: res.endDate,

        dateRange: [],
        concurrentNum: res.concurrentNum,
        keepLength: res.keepLength,
        drought: res.drought
      }
    })
  }
}
